import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as productsjG7PZMr810Meta } from "/opt/build/repo/pages/products.vue?macro=true";
import { default as verifyPPsbf6HYMxMeta } from "/opt/build/repo/pages/verify.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/opt/build/repo/pages/products.vue").then(m => m.default || m)
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/opt/build/repo/pages/verify.vue").then(m => m.default || m)
  }
]